import PropTypes from 'prop-types'
import React from 'react'
import StyleWrapper from 'common/components/StyleWrapper'
import CommonLink from 'common/components/entities/Button/Link'
import { TEST_ID } from './OpenUrlButton'

const wrapperStyles = {
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
}

function BlogLinkButton({ entity }) {
  const { options, mobileOptions, styles, mobileStyles } = entity

  const stylesWithAlignSelf = styles?.alignSelf
    ? { alignSelf: styles.alignSelf }
    : {}
  const mobileStylesWithAlignSelf = mobileStyles?.alignSelf
    ? { alignSelf: mobileStyles.alignSelf }
    : {}

  return (
    <StyleWrapper
      styles={{...wrapperStyles, ...stylesWithAlignSelf}}
      mobileStyles={{...mobileStyles, ...mobileStylesWithAlignSelf}}
      desktop={options.appearance.desktop}
      mobile={options.appearance.mobile}
    >
      <CommonLink
        dataTestId={TEST_ID}
        attrId={options.attrId}
        styles={styles}
        mobileStyles={mobileStyles}
        borderType={options.borderType}
        mobileBorderType={mobileOptions.borderType}
        subText={options.subText}
        hoverStyles={{
          textColor: options?.hover?.textColor,
          backgroundColor: options?.hover?.backgroundColor,
          subTextColor: options?.hover?.subTextColor,
          transform: options?.hover?.transform,
          offset: options?.hover?.offset,
        }}
        hoverMobileStyles={{
          textColor: mobileOptions?.hover?.textColor,
          backgroundColor: mobileOptions?.hover?.backgroundColor,
          subTextColor: mobileOptions?.hover?.subTextColor,
          transform: mobileOptions?.hover?.transform,
          offset: mobileOptions?.hover?.offset,
        }}
        subTextStyles={{
          color: options.subTextColor,
          fontSize: options.subTextFontSize,
          fontFamily: options.subTextFontFamily,
          fontStyle: options.subTextFontStyle,
          fontWeight: options.subTextFontWeight,
        }}
        subTextMobileStyles={{
          color: mobileOptions.subTextColor,
          fontSize: mobileOptions.subTextFontSize,
          fontFamily: mobileOptions.subTextFontFamily,
          fontStyle: mobileOptions.subTextFontStyle,
          fontWeight: mobileOptions.subTextFontWeight,
        }}
        iconClassNameBefore={entity.options.iconClassNameBefore}
        iconClassNameAfter={entity.options.iconClassNameAfter}
        href={options.blogLink}
        rel={options.rel}
      >
        {options.text}
      </CommonLink>
    </StyleWrapper>
  )
}

BlogLinkButton.propTypes = {
  entity: PropTypes.shape({
    options: PropTypes.shape({
      attrId: PropTypes.string,
      text: PropTypes.string,
      blogLink: PropTypes.string,
      subText: PropTypes.string,
      borderType: PropTypes.string,
      rel: PropTypes.string,
      appearance: PropTypes.shape({
        desktop: PropTypes.bool.isRequired,
        mobile: PropTypes.bool.isRequired,
      }).isRequired,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
      iconClassNameBefore: PropTypes.string,
      iconClassNameAfter: PropTypes.string,
    }),
    mobileOptions: PropTypes.shape({
      borderType: PropTypes.string.isRequired,
      subTextColor: PropTypes.string,
      subTextFontSize: PropTypes.string,
      subTextFontFamily: PropTypes.string,
      subTextFontStyle: PropTypes.string,
      subTextFontWeight: PropTypes.string,
    }),
    mobileStyles: PropTypes.objectOf(PropTypes.string),
    styles: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
}

export default BlogLinkButton
